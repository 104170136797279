import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Auth } from "aws-amplify";
import Button from '@material-ui/core/Button';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';
import { Toast } from "./../utils/notifications";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


export default function AppHeader() {
  const history = useHistory();
  const classes = useStyles();

  const handleLogout = async () => {
    try {
        await Auth.signOut();
        Toast("Success!!", "Logged out successfully.", "success");
        history.push("/signin");
    } catch (error) {
        Toast("Error!!", error.message, "danger");
    }
  };

  const newCustomer = () => {
    try {
      history.push("/new");
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Jiko Customer Dashboard
          </Typography>
          <Button color="inherit" onClick={newCustomer}><PersonAddTwoToneIcon/></Button>
          <Button color="inherit" onClick={handleLogout}><ExitToAppTwoToneIcon/></Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React, {Component} from 'react';
import { API } from "../configs/aws";
import axios from 'axios';
import { Auth } from "aws-amplify";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


export default class CustomerDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  //Function which is called when the component loads for the first time
  componentDidMount() {
    this.getCustomerDetails(this.props.val)
  }

  //Function which is called whenver the component is updated
  componentDidUpdate(prevProps) {
    //get Customer Details only if props has changed
    if (this.props.val !== prevProps.val) {
      this.getCustomerDetails(this.props.val)
    }
  }

  //Function to get authentication and load the customer details data from the API.
  getCustomerDetails(id) {
    Auth.currentSession().then(session => {
      axios.get(API.URL_BASE + 'customer/' + id,
        { headers: {
			    'accesstoken': session.getAccessToken().getJwtToken() 
        }}).then(response =>  this.setState({customerDetails: response}));
    })
    .catch(err => console.log(err));
  };

  render() {
    if (!this.state.customerDetails) return (<p>Loading Data</p>);
    let userData= this.state.customerDetails.data.records[0];
    return (
    <Card style={ {minWidth: "275"} }>
      <CardContent>
        <Typography variant="h5" component="h2">
          {userData.name}
        </Typography>
        <Typography style={{ marginBottom: "12" }} color="textSecondary">
          Customer details
        </Typography>
        <Divider />
        <Typography variant="body2" component="p">
          <p>Email : {userData.email}</p>
          <p>Phone : {userData.phone}</p>
          <p>Address : {userData.address}</p>
          <p>Job Profile : {userData.job}</p>
          <p>Additional Info : {userData.notes}</p>
        </Typography>
      </CardContent>
    </Card>
    )
  }
}


import React from "react";
import Amplify from "aws-amplify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Confirmation from "./components/Confirmation";
import { COGNITO } from "./configs/aws";
import Customers from "./components/Customers";
import EditCustomer from "./components/EditCustomer";
import Login from "./components/Login";
import NewCustomer from "./components/NewCustomer";
import ProtectedRoute from "./components/ProtectedRoute";
import Signup from "./components/Signup";


Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

const App: React.FC = () => {
  return (
    <Router>
        <Switch>
          <Route path="/signup">
            <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
              <Signup />
            </Card>
          </Route>
          <Route path="/signin">
            <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
              <Login />
            </Card>
          </Route>
          <Route path="/confirmation">
            <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
              <Confirmation />
            </Card>
          </Route>
          <Route path="/new">
            <ProtectedRoute component={NewCustomer} />
          </Route>
          <Route path="/edit/:customerId">
            <ProtectedRoute component={EditCustomer} />
          </Route>
          <Route path="/">
            <ProtectedRoute component={Customers} />
          </Route>
        </Switch>
    </Router>
  );
};


export default App;

import React, { useEffect, useState } from "react";
import { API } from "../configs/aws";
import AppHeader from './AppHeader';
import { Auth } from "aws-amplify";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import { Link, useHistory, useParams } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Toast } from "./../utils/notifications";
import { useInput } from "./../utils/forms";


const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const EditCustomer: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { value: name, setValue: setName, bind: bindName } = useInput("");
  const { value: email, setValue: setEmail, bind: bindEmail } = useInput("");
  const { value: phone, setValue: setPhone, bind: bindPhone } = useInput("");
  const { value: address, setValue: setAddress, bind: bindAddress } = useInput("");
  const { value: notes, setValue: setNotes, bind: bindNotes } = useInput("");
  let { customerId } = useParams();

  useEffect(() => {
     Auth.currentSession().then(session => {
      axios.get(API.URL_BASE + 'customer/' + customerId,
        { headers: {
			    'accesstoken': session.getAccessToken().getJwtToken() 
        }}).then(result => {
          let customerData= result.data.records[0];
          setName(customerData.name);
          setEmail(customerData.email);
          setPhone(customerData.phone);
          setAddress(customerData.address);
          setNotes(customerData.notes);
        }, error => {
         Toast("Error!!", error.message, "danger");
       })
    })
  }, [customerId, setName, setEmail, setPhone, setAddress, setNotes]);


  const handleUpdate = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    Auth.currentSession().then(session => {
      axios.put(API.URL_BASE + 'customer/' + customerId,{
        name: name,
        email: email,
        phone: phone,
        address: address,
        notes: notes
      }, {headers: { 'accesstoken': session.getAccessToken().getJwtToken() }})
      .then(result => {
          Toast("Success!!", "Customer updated.", "success");
          setLoading(false);
          history.push("/");
        }, error => {
         Toast("Error!!", error.message, "danger");
         setLoading(false);
       })
    })
  };

  return (
    <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppHeader/>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ width: 500, margin: "100px auto", padding: "40px" }}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onSubmit={handleUpdate}
          >
            <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
              {" "}
              Edit Customer {customerId}
            </h1>
            <Field label="Name" {...bindName} />
            <Field label="Email" {...bindEmail} type="email" />
            <Field label="Phone" {...bindPhone} type="tel" />
            <Field label="Address" {...bindAddress} />
            <Field label="Notes" {...bindNotes} />
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading}
            >
              {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
              Update
            </Button>
            <DLink to="/">go to dashboard &rarr;</DLink>
          </form>
          </Card>
        </Grid>
     </Grid>
  );
};

export default EditCustomer;



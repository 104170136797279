import React, { useEffect, useState } from "react";
import { API } from "../configs/aws";
import AppHeader from './AppHeader';
import { Auth } from "aws-amplify";
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios'
import Button from "@material-ui/core/Button";
import CustomerDetails from './CustomerDetails';
import ConfirmationDialog from './ConfirmationDialog';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import { Toast } from "./../utils/notifications";
import { useHistory } from "react-router-dom";

interface Customer {
  id: string;
  name: string;
  email: string;
  phone: string;
}

const Customers: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<Array<Customer>>([]);
  const [selectedId, setSelectedId] = useState<string>("");

  useEffect(() => {
    Auth.currentSession().then(session => {
      axios.get(API.URL_BASE + 'customers',
        { headers: {
			    'accesstoken': session.getAccessToken().getJwtToken() 
        }}).then(result => setData(result.data.records));
    })
    .catch(err => console.log(err));
  }, []);

  const editCustomer = () => {
    try {
      history.push("/edit/" + selectedId);
    } catch (error) {
      Toast("Error!!", error.message, "danger");
    }
  };

  const deleteCustomer = async () => {
    Auth.currentSession().then(session => {
      axios.delete(API.URL_BASE + 'customer/' + selectedId,
        { headers: {
			    'accesstoken': session.getAccessToken().getJwtToken() 
        }}).then(value => removeCustomerFromList(selectedId));
    })
    .catch(error => Toast("Error!!", error.message, "danger"));
  };

  const removeCustomerFromList= (customerId: string) => {
    for( var i = 0; i < data.length; i++){ 
        if (data[i].id === customerId) { 
            data.splice(i, 1); 
        }
    }
    setData(data);
    setSelectedId("");
  };

  return (
    <div className="addmargin">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppHeader/>
        </Grid>
        <Grid item xs={4}>
          <List>
            { data.map(customer =>
              <ListItem button divider onClick={() => setSelectedId(customer.id)}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonOutlineTwoToneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={customer.name}
                  secondary={
                    <React.Fragment>
                      <p>{customer.email}</p>
                      <p>{customer.phone}</p>
                    </React.Fragment>
                  }
                ></ListItemText>
              </ListItem>,
            )}
          </List>
        </Grid>
        <Grid item xs={8}>
          {selectedId ? (
          <Paper style={{ width: "100%", margin: "20px auto", padding: "20px" }}>
            <CustomerDetails val={selectedId}/>
            <p/>
            <div>
              <Button variant="contained" color="primary" onClick={editCustomer}>Edit</Button>
              &nbsp;
              <ConfirmationDialog label="Delete" callback={deleteCustomer}/>
            </div>
          </Paper>
           ) : "" }
        </Grid>
      </Grid>
    </div>
  )
};

export default Customers;



